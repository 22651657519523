import isEmpty from '../modules/isEmpty';
import API from '../components/api';

import FFAEventComponentRider from '../components/ffa-event-component-rider';

export default  {

	template: require('/src/components/ffa-event-component-ranking.html'),
	props: ['combinedId'],
	watch: {
		async '$reloadKey.count'() {
			if (this.combinedId === this.$reloadKey.message.id) {
				await this.getCombined();
			}
		},
	},
	components: {
		FFAEventComponentRider,
	},
	data() {
		return {
			combined: [],
			combinedLoading: false,
			combinedError: null,
		};
	},
	async created() {
		await this.getCombined();
	},
	methods: {
		async getCombined() {
			this.combinedLoading = false;
			this.combinedError = null;

			this.combined = null;

			try {
				this.combined = await API.getCombined(this.combinedId);

				if (isEmpty(this.combined) === false && isEmpty(this.combined.combinedResult) === false) {
					//console.log('combinedResult:', this.combined.combinedResult);
					this.combined.combinedResult = this.combined.combinedResult.sort((a, b) => a.displayRank - b.displayRank);

					this.combined.combinedResult = this.combined.combinedResult.map((r) => {
						if (r.rank === 999) {
							r.rank = '';
						}
						return r;
					});

					this.combined.combinedResult = this.combined.combinedResult.filter(r => (r.status !== 'NO_RESULT'));
				}
			} catch (ex) {
				if (ex.message === 'Failed to fetch') {
					this.combinedError = 'Serveur déconnecté';
				} else if (ex.message === 'JSON parsing error') {
					this.combinedError = 'Combiné non disponible';
				} else {
					this.combinedError = ex.message;
				}
			} finally {
				this.combinedLoading = false;
			}
		},
		toggleDisplayNext(ev) {
			const el = ev.currentTarget;

			el.nextElementSibling.classList.toggle('d-none');
			
			const toggleArrowEl = el.querySelector('[data-ref=toggle-arrow]');
			if (toggleArrowEl !== null) {
				toggleArrowEl.classList.toggle('fa-angle-up');
			}
		},
		toggleDisplayCurrent(ev) {
			const el = ev.currentTarget;

			el.classList.toggle('d-none');
			
			const toggleArrowEl = el.previousElementSibling.querySelector('[data-ref=toggle-arrow]');
			if (toggleArrowEl !== null) {
				toggleArrowEl.classList.toggle('fa-angle-up');
			}
		},
		hasToggleDisplay(result) {
			return isEmpty(result.results) === false;
		},
	},
}
